const CouplesTwo = [
  "What are the circumstances in which you think I am at my best? Who am I with and what am I doing?",
  "What did you learn from your parents' relationship that you would like to apply or avoid in ours?",
  "Have I ever betrayed your trust in a big or small way? If so, how can we rebuild that trust?",
  "When was the last time I made you feel loved when you didn't feel lovable?",
  "How does social media affect me in my day-to-day life and how does it affect our relationship?",
  "What small thing had a significant impact on our relationship?",
  "How do you define cheating?",
  "What is one small way we can improve our sex life?",
  "Does our age difference, or lack thereof, affect our relationship in any way?",
  "Have I changed your worldview in any big or small way?",
  "How have you seen me grow in this relationship?",
  "When was the last time I unintentionally hurt you?",
  "How do I show you that I love you without telling you?",
  "What have I helped you appreciate about yourself?",
  "How do our strengths and weaknesses complement each other?",
  "What recent experience made you feel closer to me?",
  "Do you have any insecurities from previous relationships that you have carried into this one? If so, what are they?",
  "What is the most important lesson a past relationship has taught you that you have applied to ours?",
  "What about you is hardest for me to love?",
  "What is the best gift I have given you, both material and not material?",
  "What immediately attracted you to me? What has attracted you more over time?",
  "What feelings are hard for you to communicate to me and how can I make it easier?",
  "What have you been extra sensitive to lately?",
  "How do you think my job affects our relationship?",
  "What are you currently working through that I am unaware of?",
  "What did our worst argument teach you?",
  "What is the most romantic thing I have done for you recently?",
  "What has this relationship taught you about yourself?",
  "What was the hardest thing for me to reveal about myself to you?",
  "What can we laugh about now that was once daunting in our relationship?",
  "What do you overthink most in our relationship, if anything?",
  "What have you learned not to take personally with me?",
  "Finish the sentences: I knew I really liked you when _____. I knew I loved you when _____.",
  "When was the last time someone betrayed your trust? How did that affect your ability to trust today?",
  "What is the sexiest thing I do without realizing it?",
  "What compromise have you seen me make in our relationship that you are most grateful for?",
  "How has your definition of love changed over time, if at all?",
  "How have I changed you for the better?",
  "What is the hardest compromise you have had to make in this relationship?",
  "What is something we used to do at the beginning of our relationship that you miss the most?",
  "What is one way our relationship has gotten better over time?",
  "In what ways is this relationship different from any other you have had?",
  "What title would you give this chapter of our sex life?",
  "Which fight of ours did you struggle with the most and what was particularly hard for you about it?",
  "What's one small thing I do that tells you everything you need to know about me?",
];

export default CouplesTwo;
