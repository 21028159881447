import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Flashcards from "./components/Flashcards";

import { useState } from "react";

import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Box,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import { SlideFade } from "@chakra-ui/react";
export default function Start() {
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const { isOpen, onToggle } = useDisclosure();
  const [level, setLevel] = useState("");
  const [isReset, setIsReset] = useState(true);
  const handleLevelOneClick = () => {
    onToggle();
    setLevel("Couples One");
    setIsReset(false);
  };

  const handleLevelTwoClick = () => {
    onToggle();
    setLevel("Couples Two");
    setIsReset(false);
  };
  const handleLevelThreeClick = () => {
    onToggle();
    setLevel("Couples Three");
    setIsReset(false);
  };

  const handleReset = () => {
    onToggle();
    setIsReset(true);
  };

  return (
    <div>
      <Navbar />
      <div style={{height:'100vh'}}>
        <Container maxW={"5xl"}>
          <Stack
            textAlign={"center"}
            align={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}
          >
            <Heading
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
              lineHeight={"110%"}
            >
              <Text as={"span"} color={"pink.400"}>
                Couples:{" "}
              </Text>
              Pick your{" "}
              <Text as={"span"} color={"pink.400"}>
                level
              </Text>
            </Heading>
            <Text color={"gray.500"} maxW={"3xl"}>
              Warning: honest answers may surface.
            </Text>

            <Stack spacing={6} direction={isSmallScreen ? "column" : "row"}>
              <Button
                rounded={"full"}
                px={6}
                colorScheme={"pink"}
                bg={"pink.300"}
                _hover={{ bg: "pink.400" }}
                onClick={handleLevelOneClick}
                disabled={!isReset}
              >
                Level 1: Romance
              </Button>
              <Button
                rounded={"full"}
                px={6}
                colorScheme={"pink"}
                bg={"pink.500"}
                _hover={{ bg: "pink.600" }}
                onClick={handleLevelTwoClick}
                disabled={!isReset}
              >
                Level 2: Exploration
              </Button>
              <Button
                rounded={"full"}
                px={6}
                colorScheme={"pink"}
                bg={"pink.700"}
                _hover={{ bg: "pink.800" }}
                onClick={handleLevelThreeClick}
                disabled={!isReset}
              >
                Level 3: Stability
              </Button>
              {/* <Button
                rounded={"full"}
                px={6}
                colorScheme={"purple"}
                bg={"purple.400"}
                _hover={{ bg: "purple.500" }}
                onClick={handleEverything}
                disabled={!isReset}
              >
                Everything
              </Button> */}
              <Button
                rounded={"full"}
                px={6}
                onClick={handleReset}
                disabled={isReset}
              >
                Reset
              </Button>
            </Stack>

            <SlideFade in={isOpen} offsetY="20px">
              <Box color="white">
                <div id="flashcards">
                  <Flashcards level={level} />
                </div>
              </Box>
            </SlideFade>
          </Stack>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
