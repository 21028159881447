import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import levelOne from "../data/LevelOne";
import levelTwo from "../data/LevelTwo";
import levelThree from "../data/LevelThree";
import CouplesOne from '../data/CouplesOne'
import CouplesTwo from '../data/CouplesTwo'
import CouplesThree from '../data/CouplesThree'
import { useState, useEffect } from "react";

export default function Card(props) {
  const { level, currentQuestion  } = props;
  const [question, setQuestion] = useState("");
  const bgColor1 = useColorModeValue("white", "gray.900");
  const bgColor2 = useColorModeValue("gray.700", "white");
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");

  useEffect(() => {
    let allQuestions = [];
    if (level === "Level One") {
      allQuestions = levelOne;
    } else if (level === "Level Two") {
      allQuestions = levelTwo;
    } else if (level === "Level Three") {
      allQuestions = levelThree;
    } else if (level === "Everything") {
      allQuestions = [...levelOne, ...levelTwo, ...levelThree];
    } else if (level === "Couples One") {
      allQuestions = CouplesOne;
    } else if (level === "Couples Two") {
      allQuestions = CouplesTwo;
    } else if (level === "Couples Three") {
      allQuestions = CouplesThree;
    }
    
    const currentFlashcard = allQuestions[currentQuestion];
    if (currentFlashcard) {
      setQuestion(currentFlashcard);
    }
  }, [level, currentQuestion]);

  return (
    <Center>
        <Box
          maxW={isSmallScreen ? "300px" : "800px"}
          w={isSmallScreen ? "300px" : "600px"}
          h={isSmallScreen ? "175px" : "300px"}
          bg={bgColor1}
          boxShadow={"2xl"}
          rounded={"md"}
          p={isSmallScreen ? 3 :6}
          overflow={"hidden"}
        >
          <Stack>
            <Text
              color={"green.500"}
              textTransform={"uppercase"}
              fontWeight={800}
              fontSize={isSmallScreen ? "9px" : "sm"}
              letterSpacing={1.1}
            >
              {level}
            </Text>
            <Heading color={bgColor2} fontSize={isSmallScreen ? "xl" : "4xl"} fontFamily={"body"}>
              {level === "Level One"
                ? "Discovery"
                : level === "Level Two"
                ? "Empathy"
                : level === "Level Three"
                ? "Clarity"
                : level === "Everything"
                ? "Everything"
                : level === "Couples One"
                ? "Romance"
                : level === "Couples Two"
                ? "Exploration"
                : level === "Couples Three"
                ? "Stability"
                : "Pick a level."}
            </Heading>
            <Text Text color={"gray.500"} fontSize={isSmallScreen ? "16px" : "24px"}>
              {question}
            </Text>
          </Stack>
        </Box>
    </Center>
  );
}
