import { Stack, IconButton, useMediaQuery } from "@chakra-ui/react";
import Card from "./Card";
import { useState } from "react";
import levelOne from "../data/LevelOne";
import levelTwo from "../data/LevelTwo";
import levelThree from "../data/LevelThree";
import CouplesOne from "../data/CouplesOne";
import CouplesTwo from "../data/CouplesTwo";
import CouplesThree from "../data/CouplesThree";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

export default function Flashcards(props) {
  const { level } = props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [seenQuestions, setSeenQuestions] = useState([]);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");
  const allQuestions = [];
  if (level === "Level One") {
    allQuestions.push(...levelOne);
  } else if (level === "Level Two") {
    allQuestions.push(...levelTwo);
  } else if (level === "Level Three") {
    allQuestions.push(...levelThree);
  } else if (level === "Everything") {
    allQuestions.push(...levelOne, ...levelTwo, ...levelThree);
  } else if (level === "Couples One") {
    allQuestions.push(...CouplesOne);
  } else if (level === "Couples Two") {
    allQuestions.push(...CouplesTwo);
  } else if (level === "Couples Three") {
    allQuestions.push(...CouplesThree);
  }

  const handleNextQuestion = () => {
    if (currentQuestion === allQuestions.length - 1) {
      // if the current question is the last question, return from the function
      return;
    } else {
      setCurrentQuestion((currentQuestion + 1) % allQuestions.length);
      // add the current question to the list of seen questions to ensure it is not shown again
      setSeenQuestions([...seenQuestions, allQuestions[currentQuestion]]);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestion === 0) {
      // if the current question is the first question, return from the function
      return;
    } else {
      setCurrentQuestion(
        (currentQuestion - 1 + allQuestions.length) % allQuestions.length
      );
    }
  };

  return (
    <div id="flashcard-container">
      {isSmallScreen ? (
        <div id="responsive">
          <Stack
            direction={"column"}
            spacing={isSmallScreen ? 3 : 20}
            align={"center"}
            justify="between"
          >
            <Stack
              direction={"row"}
              spacing={isSmallScreen ? 3 : 20}
              align={"center"}
              justify="between"
            >
              <IconButton
                variant="solid"
                colorScheme="green"
                aria-label="Call Sage"
                fontSize="20px"
                icon={<FaAngleLeft />}
                rounded="full"
                onClick={handlePrevQuestion}
              />
              <IconButton
                variant="solid"
                colorScheme="green"
                aria-label="Call Sage"
                fontSize={"20px"}
                icon={<FaAngleRight />}
                onClick={handleNextQuestion}
                rounded="full"
              />
            </Stack>
            <Card
              level={level}
              currentQuestion={currentQuestion}
              handleNextQuestion={handleNextQuestion}
              handlePrevQuestion={handlePrevQuestion}
            />
          </Stack>
        </div>
       ) : (
      <div id="normal">
        <Stack
          direction={"row"}
          spacing={isSmallScreen ? 3 : 20}
          align={"center"}
          justify="between"
        >
          <Stack direction={"row"} spacing={90} fontSize={"sm"}>
            <IconButton
              variant="solid"
              colorScheme="green"
              aria-label="Call Sage"
              fontSize="20px"
              icon={<FaAngleLeft />}
              rounded="full"
              onClick={handlePrevQuestion}
            />
          </Stack>
          <Card
            level={level}
            currentQuestion={currentQuestion}
            handleNextQuestion={handleNextQuestion}
            handlePrevQuestion={handlePrevQuestion}
          />
          <Stack direction={"row"} spacing={90} fontSize={"sm"}>
            <IconButton
              variant="solid"
              colorScheme="green"
              aria-label="Call Sage"
              fontSize={"20px"}
              icon={<FaAngleRight />}
              onClick={handleNextQuestion}
              rounded="full"
            />
          </Stack>
        </Stack>
      </div>
      )}
    </div>
  );
}
