const CouplesOne = [
  "What assumption did you make about me that ended up being accurate?",
  "What about my appearance caught your attention first?",
  "What was I wearing on our first date?",
  "In one word, how would you describe our first date?",
  "Before we met, did you ever check out my social media? If so, what stood out to you?",
  "What is my current go-to Postmates order?",
  "How important do you feel physical affection is to me? Explain.",
  "What did your friends think of me when we first started dating?",
  "In your opinion, how important are gifts to me? Explain.",
  "What do you think I would like to do more often with you?",
  "What do you think I am most sensitive about?",
  "How do you think I feel about marriage? Explain.",
  "What did you expect I would be like in bed? Was this accurate?",
  "What about me most intrigued you before we started dating?",
  "Early on, what was the hardest thing for you to understand about me?",
  "What do you think is currently causing me the most stress?",
  "Early on, was there anything about me that intimidated you?",
  "If you could give yourself a warning about me at the start of our relationship, what would it be?",
  "What do you think is my biggest goal for this year?",
  "In your opinion, how do I typically show or give love?",
  "What is my biggest pet peeve? Explain.",
  "In one word, how would you describe my relationship with work? Explain.",
  "What is your favorite thing for me to wear? (Excluding nothing.)",
  "What is my specific coffee order?",
  "Before we met, what did you think my type was?",
  "What did my friend group reveal to you about me?",
  "Please describe the first time we met from your perspective in detail.",
  "What do you think would most likely get me fired?",
  "What do I dislike that most people love?",
  "What do I love that most people dislike?",
  "In your opinion, how important are birthdays and holidays to me? Explain.",
  "Who do you think was more nervous on our first date? Explain.",
  "If our relationship was a Netflix series, what would it be called? What episode are we on?",
  "In your opinion, what would be my perfect date? Describe in detail.",
  "What was the first thing you noticed about me that was not physical?",
  "What assumption did you make about me that ended up being incorrect?",
  "On a scale of 1-10, how open do you feel I am with you? Explain.",
  "Did you make any assumptions about me based on my age? If so, what were they?",
  "How do I show you that I'm upset without telling you I'm upset?",
  "What would a day of completely spoiling me look like?",
  "During a fight or disagreement, what do you think I am most sensitive about?",
  "Which celebrity couple do you think we are most like?",
  "If we weren't in a relationship, what pick-up line would you use on me?",
  "If our first kiss was a song, what would it be called?",
  "Do you think I have any unconventional views on love?"
  ];
  
  export default CouplesOne;