import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Link
  } from '@chakra-ui/react';
  import picture from '../assets/standing-21.svg'
  import Campfire from './Campfire'
  
  export default function CallToActionWithIllustration() {
    return (
      <Container maxW={'5xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Let's get to know{' '}
            <Text as={'span'} color={'orange.400'}>
              each other
            </Text>
          </Heading>
          <Text color={'gray.500'} maxW={'3xl'}>
            Those late nights shouldn't be wasted. 
            Whether you are a little tipsy or just want to pass some time,
            you should get to know the other and make friendships or relationships forever.
          </Text>
          <Stack spacing={6} direction={'row'}>
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'orange.400'}
              _hover={{ bg: 'orange.500' }}
              >
              <Link href='/start' style={{textDecoration:'none'}}>
              Get started
              </Link>
            </Button>
          </Stack>
          <Flex   
          align={'center'}>
            <div>
              <Campfire/>
            </div>
          </Flex>
        </Stack>
      </Container>
    );
  }
  
  export const Illustration = (props) => {
    return (
      <img src={picture} alt='illustration'/>
    );
  };