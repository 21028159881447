const levelTwo = [
  "What is your defining characteristic?",
  "What's the most pain you've ever been in that wasn't physical?",
  "How are you, really?",
  "What is the last thing your mom has lied to you about?",
  "What's your father's name and one thing about him?",
  "What have I tolerated from people in the past that I no longer have space for?",
  "Something that brought a smile to my face this week was ___.",
  "What are you feeling a lot of lately?",
  "What do you need right now but aren't communicating?",
  "What are you currently working through that others don't see?",
  "What was the biggest turning point in your dating life?",
  "What is your heart telling you?",
  "What is your most toxic trait you can admit to?",
  "Who in your life deserves the biggest thank you? Let them know if you can.",
  "What are you overthinking right now?",
  "What did you last relationship teach you about yourself?",
  "What belief about yourself no longer serves you?",
  "What do you need to forgive yourself for?",
  "What's the hardest lesson you've learned in love?",
  "You hurt me, but thank you for ___.",
  "Who in your life can you be your most vulnerable with?",
  "Which one of your parent's personality traits do you want to keep/let go of?",
  "Dear younger self: ____.",
  "Think of someone you admire. Why did this person come to mind?",
  "Are you lying to yourself about anything?",
  "What's your favorite part of yourself that isn't physical?",
  "What's the hardest part about dating you?",
  "I feel loved when ___.",
  "What is something you've learned about yourself this week?",
  "Self love is ___.",
  "What was the lesson in your most recent painful experience?",
  "I know I'm in love when ___.",
  "What's your favorite song lyric you can think of off the top of your head?",
  "How did you get over your first love?",
  "What's the best lesson an ex has ever taught you?",
  "Is there anyone who's changed your life but doesn't know it?",
  "What do you need right now, more than anything?",
  "When was the last time you felt lucky to be you?",
  "What do you need to accept today?",
  "When was the last time you felt most yourself?",
  "What's your mother's name and the most valuable lesson she's taught you thus far?",
  "What are you most grateful for in this current moment?",
  "What are you still trying to prove to yourself?",
  "What have you been taking for granted lately?",
  "Is there a feeling you miss?",
  "What would your younger self not believe about your life today?",
  "If you could have it your way: Who would you be with? Where would you be? And what would you be doing?",
  "What do you need to hear right now?",
  "What title would you give this chapter in your life?",
  "What dating advice would you give your younger self?",
  "What lesson should you have learned by now?",
  "When was the last time you were able to use your pain to help someone else?",
  "What would you never want to change about yourself?",
  "What question did this year leave you with?",
  "What about yourself is hardest to admit?",
  "What question are you trying to answer most in your life right now?",
  "Finish the sentences: strangers would describe me as _____. Only I know that I am ______",
  "When you're asked how are you, how often do you answer truthfully?",
  "Has a stranger ever changed your life?",
  "What is the most unexplainable thing that's ever happened to you?",
  "Describe your perfect day!",
  "Have you changed your mind about anything recently?",
  "What's been the best compliment a stranger has ever given you?",
  "What is a compliment you wish you received more frequently?",
  "What do you crave more of?",
  "What has been your earliest recollection of happiness?",
  "What are you more afraid of, failure or success? And why?",
  "How would you describe the feeling of being in love in one word?",
  "When was the last time you surprised yourself?",
  "What's been your happiest memory this past year?",
  "Do you think the image you have of yourself matches the image people see you as?",
  "If you have, when was the moment you realized you weren't invincible?",
  "What lesson took you the longest to unlearn?",
  "What part of your life works? What part of your life hurts?",
  "What is your 1st love's name and the reason you fell in love with him/her?",
  "What's your mother's name? And the most beautiful thing about her?",
  "If you could get to know someone in your life on a deeper level, who would it be and why?",
  "Are you missing anyone right now? Do you think they are missing you too?",
  "What is a dream you've let go of?",
  "What is the last thing you lied to your mother about?",
  "Have you ever told someone I love you but didn't mean it? if so, why?",
  "How can you become a better person?",
];

export default levelTwo;
