const CouplesThree = [
  "Thank you for accepting ___ about me.",
  "What do you wish we did more of together?",
  "Which of your weaknesses do I help complement the most?",
  "What are you aware of that I am too hard on myself about?",
  "How have I changed since we first met? What about me has remained the same?",
  "On a scale of 1-10, how vulnerable do you think I have been in this game? Explain.",
  "What is one similarity between us that you love?",
  "What is one difference between us that you love?",
  "How would you describe the way I love you to a stranger?",
  "What part of my heart do you wish you could heal?",
  "What will you do differently during our next argument?",
  "What do you think I struggle to open up about in our relationship?",
  "Is there anything that you find difficult to open up to me about?",
  "Which answer of mine will you still be thinking about when this game is over?",
  "Which question felt most vulnerable for you to ask? Explain.",
  "Which question felt most vulnerable for you to answer? Explain.",
  "What is a lesson about love that you will take away from this game?",
  "Thank you for helping me realize ___ about myself.",
  "What did this conversation teach you about our relationship? What did it teach you about yourself?",
  "What about our relationship are you proudest of?",
  "What do you believe I don't give myself enough credit for?",
  "What goal would feel best for you to accomplish this year and how can I support you in that?",
  "Is there anything you still don't know about me that you wish you did?",
  "Which answer of mine surprised you the most throughout this game?",
  "If you could prescribe one thing for our relationship, what would it be and why?",
  "When in this game did you feel most connected to me?",
  "What are you most aware of that I am qualified to give relationship advice about?",
  "What is your favorite ritual of ours? What is a ritual that you would like to create for us moving forward?",
  "What do you think is the defining characteristic of our couple?",
  "What would you tell yourself on our first date if you had the chance?",
  "In your opinion, how am I holding myself back the most?",
  "If we started a business together, what would it be?",
  "What most excites you about our future?",
  "What do we have in this relationship that you never thought was possible?",
  "What is one thing you would never change about our relationship?",
  "What could other couples learn from us?",
  "When was the last time you felt lucky to be my significant other? (Saying 'all the time' does not count.)",
  "I am proudest of you for ___.",
  "What is a superpower of mine that I may be unaware of?",
  "Is there anything you still don't know about me that you wish you did?",
  "What goal would feel best for you to accomplish this year?",
  "What do you believe I don't give myself enough credit for?",
  "What about our relationship are you proudest of?",
  "What did this conversation teach you about our relationship? What did it teach you about yourself?",
  "Thank you for helping me realize ___ about myself.",
  ];
  
  export default CouplesThree;
  