import { Box, Heading, Text, Link } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
export default function ContactDone() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={"100px"} color={"green.500"} />
      <Heading as="h2" size="2xl" mt={6} mb={2}>
        Thanks for sharing!
      </Heading>
      <Text color={"gray.500"} mt={6}>
        Click <Link href="/">here </Link>to return back to home page!
      </Text>
    </Box>
  );
}
