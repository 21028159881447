import React from "react";
import '../css/campfire.scss'

export default function Campfire() {
  return (
    <div class="fireplace">
      <div class="blur">
        <div class="fireplace__flame_big"></div>
      </div>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <section class="fireplace__log"></section>
      <main class="fireplace__spark"></main>
      <main class="fireplace__spark"></main>
      <main class="fireplace__spark"></main>
      <main class="fireplace__spark"></main>
      <div class="blur fix">
        <div class="fireplace__flame"></div>
      </div>
      <div class="fireplace__light"></div>
    </div>
  );
}
