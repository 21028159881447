import {
    Box,
    Container,
    Heading,
    Text,
    Stack,
    Divider,
    Link
  } from '@chakra-ui/react';
  
  export default function AboutMain() {
    return (
      <Box p={4}>
        <Stack spacing={8} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={'3xl'}>About</Heading>
          <Text color={'gray.600'} fontSize={'xl'}>
            CoupleFriends is a online digital card game that ventures to destroy the borders 
            of casual friendships and to encourage deeper and intimate conversations.
          </Text>
          <Divider/>
          <Text color={'gray.600'} fontSize={'xl'}>
            I made this game for fun during winter break. I would appreciate some feedback, but otherwise I hope you enjoy this platform!
          </Text>
          <Divider/>
          <Text color={'gray.600'} fontSize={'xl'}>
            Also, if you know of any features I should add to this game (inappropriate or appropriate) feel free to tell me <Link href='/contact'>here!</Link>
          </Text>
        </Stack>

      </Box>
    );
  }