const levelOne = [
  "What was your first impression of me?",
  "What do you think is the hardest part of what I do for a living",
  "What reality show do you think I'm most likely to binge watch? explain.",
  "Do I seem like a cat or dog person?",
  "What do my shoes tell you about me?",
  "Do I seem like more of a creative or analytical type? explain.",
  "How many speeding tickets do you think I've gotten in my life?",
  "Do I seem like someone who would get a name tattooed on myself?",
  "What do you think I'm most likely to splurge on?",
  "Finish the sentence: just by looking at you I'd think ____.",
  "What character would I play in a movie?",
  "Do I remind you of anyone?",
  "Who do you think my celebrity crush is?",
  "Do you think I've ever checked an ex's phone for evidence?",
  "What's the first thing you noticed about me?",
  "Do I seem like a coffee or tea person? Sweetened or unsweetened?",
  "What do you think my go to karaoke song is?",
  "If you were to buy me a present, knowing nothing about me other than what I look like, what would it be?",
  "What does my phone wallpaper tell you about me?",
  "Do I look kind? explain.",
  "Do you think I fall in love easily? why or why not?",
  "As a child, what do you think I wanted to be?",
  "Do you think I'm usually early, on time, or late to events? explain.",
  "What subject do you think I thrived in at school? Did I fail any?",
  "What compliment do you think I hear the most?",
  "Do I seem like a morning person or a night owl? why?",
  "What fast food restaurant do you think I'm most likely to drive through? What's my order?",
  "What is my body language telling you right now?",
  "What does my instagram tell you about me?",
  "Do you think plants thrive or die in my care? explain.",
  "On a scale of 1-10, how messy do you think my car is? 1 being clean 10 complete disaster",
  "Do you think I was popular in school? explain.",
  "Do you think I've ever been fired from a job? If so, what for?",
  "Do you think I intimidate others? why or why not?",
  "How likely am I to go camping? how high maintenance is my set up?",
  "What about me is most strange or unfamiliar to you?",
  "If myspace were still a thing, what would my profile song be?",
  "What about me intrigues you?",
  "What does my style tell you about me?",
  "Do you think I've ever had my heart broken?",
  "Do you think I've been in love?",
  "What is the worst assumption someone has made about you?",
];

export default levelOne;
